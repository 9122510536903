import { captureException } from '@sentry/nextjs';
import { AnnouncementBar } from 'components/AnnoucementBar';
import { featherAggregatedRating } from 'components/reviewBadge';
import { FORMATTED_DEFAULT_LOCALE } from 'constants/i18n';
import { BUSINESS_SUBDOMAIN, DEFAULT_SUBDOMAIN } from 'constants/subdomains';
import { ModalWrapper } from 'context';
import { GetStaticPathsContext, GetStaticProps } from 'next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { NextSeo, ProductJsonLd } from 'next-seo';
import ComponentManager from 'strapi/components/ComponentManager';
import { StrapiAnnouncementBarData, StrapiPageData } from 'strapi/types/utils';
import { fetchAnnouncementBar, fetchMany, fetchOne } from 'strapi/utils/api';
import { isPageMarkedForDeindex } from 'strapi/utils/seo';
import { generateStrapiPagePaths } from 'util/generateCmsPagePaths';
import { getNamespacesFromLocale } from 'util/getNamespacesFromLocale';
import { i18nLocaleFormatter } from 'util/i18nLocaleFormatter';
import { getUidFromPathSegments } from 'util/strapiPathResolver';
import { validateSubdomain } from 'util/validateSubdomain';

import styles from './style.module.scss';

const StrapiPage = ({
  page,
  announcementBar,
}: {
  page: StrapiPageData;
  announcementBar: StrapiAnnouncementBarData;
}) => {
  const {
    seo,
    tags,
    product_name: productName,
    aggregate_offer_low_price: aggregateOfferLowPrice,
  } = page;

  if (!seo) {
    throw new Error('Missing page metadata');
  }

  const { metaTitle, metaDescription, metaSocial, canonicalURL } = seo;
  const isMarkedForDeindex = isPageMarkedForDeindex(tags?.tags_list);

  if (!metaSocial || metaSocial.length === 0) {
    throw Error('Missing social media page preview data');
  }

  const [openGraphItem] = metaSocial;

  return (
    <>
      <NextSeo
        title={metaTitle}
        description={metaDescription}
        openGraph={{
          title: openGraphItem.title,
          description: openGraphItem.description,
          images: openGraphItem.image?.data
            ? [
                {
                  url: openGraphItem.image.data.attributes.url,
                  width: openGraphItem.image.data.attributes.width,
                  height: openGraphItem.image.data.attributes.height,
                  alt: openGraphItem.image.data.attributes.alternativeText,
                },
              ]
            : [],
        }}
        noindex={isMarkedForDeindex}
        canonical={canonicalURL}
      />
      {productName && aggregateOfferLowPrice && (
        <ProductJsonLd
          productName={productName}
          description={metaDescription}
          aggregateRating={featherAggregatedRating}
          brand="Feather"
          aggregateOffer={{
            lowPrice: aggregateOfferLowPrice,
            priceCurrency: 'EUR',
          }}
          images={
            openGraphItem.image?.data
              ? [openGraphItem.image?.data.attributes.url]
              : []
          }
        />
      )}
      <div className={`pt64 ${styles.container}`}>
        {announcementBar && <AnnouncementBar {...announcementBar} />}
        <ComponentManager blocks={page.items} />
      </div>
    </>
  );
};

const Page = ({
  page,
  announcementBar,
}: {
  page: StrapiPageData;
  announcementBar: StrapiAnnouncementBarData;
}) => {
  return (
    <ModalWrapper>
      <StrapiPage page={page} announcementBar={announcementBar} />
    </ModalWrapper>
  );
};

export default Page;

export const getStaticProps: GetStaticProps = async ({
  params,
  locale,
  preview,
}) => {
  // params.uid should be an array segmented by '/': https://nextjs.org/docs/pages/building-your-application/routing/dynamic-routes#catch-all-segments
  if (!params?.uid || typeof params.uid === 'string') return { notFound: true };
  const subdomain = validateSubdomain(params?.subdomain);

  if (!locale) throw Error('Locale was not set during the build time');

  const parsedUid = getUidFromPathSegments(params.uid);

  try {
    const page = await fetchOne(
      subdomain === BUSINESS_SUBDOMAIN ? 'page-businesses' : 'pages',
      {
        uid: parsedUid,
        locale,
        preview,
      }
    );
    if (!page) return { notFound: true };

    const announcementBar = await fetchAnnouncementBar({
      subdomain,
      uid: parsedUid,
      locale,
      preview,
    });

    const namespacesRequired = getNamespacesFromLocale(locale);

    const i18nProps = await serverSideTranslations(
      i18nLocaleFormatter(locale) || FORMATTED_DEFAULT_LOCALE,
      namespacesRequired
    );

    return {
      props: {
        page,
        announcementBar,
        ...i18nProps,
      },
    };
  } catch (e) {
    captureException(e);

    throw new Error('Failed to fetch CMS pages during static generation');
  }
};

export const getStaticPaths = async ({ locales }: GetStaticPathsContext) => {
  if (!locales) throw Error('Locales undefined during the build time');

  try {
    const strapiPages = (
      await Promise.all(
        locales.map((locale) => fetchMany('pages', { locale })).flat()
      )
    ).flat();

    const strapiBusinessPages = (
      await Promise.all(
        locales.map((locale) => fetchMany('page-businesses', { locale })).flat()
      )
    ).flat();

    // TODO: Add back paths for NotTranslatedWrapper [SIDE-1142]
    return {
      paths: [
        ...generateStrapiPagePaths(strapiPages, DEFAULT_SUBDOMAIN),
        ...generateStrapiPagePaths(strapiBusinessPages, BUSINESS_SUBDOMAIN),
      ],
      fallback: false,
    };
  } catch (error) {
    captureException(error);

    throw new Error('Failed to fetch CMS pages during static generation');
  }
};
